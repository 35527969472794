import React from 'react';
import { Link } from 'gatsby'
import NumberEasing from 'react-number-easing';

import {
    Container,
    Grid,
} from './styled'

import {
    Icon,
    Column,
    Heading,
    SubHeading,
    Center,
    Paragraph
} from '../../../pageStyles'

export const SocialProof = () => (
    <Container>  
        <Center>
            <Heading>NorthFPV continua a crescere</Heading>
            <Paragraph>Entra anche tu a far parte della nostra community</Paragraph>
        </Center>
        <Grid>
            <Column>
                <Center>
                    <Link href="https://www.facebook.com/NorthFPV"><Icon className="fab fa-facebook-square"/></Link>
                    <SubHeading>Gruppo Facebook</SubHeading>
                    <Heading>
                        <NumberEasing
                            value={1984}
                            speed={300}
                            ease="cubicOut"/>
                    </Heading>
                </Center>
            </Column>

            <Column>
                <Center>
                    <Link href="https://t.me/north_fpv"><Icon className="fab fa-telegram"/></Link>
                    <SubHeading>Gruppo Telegram</SubHeading>
                    <Heading>
                        <NumberEasing
                            value={415}
                            speed={300}
                            ease="cubicOut"/>
                    </Heading>
                </Center>
            </Column>

            <Column>
                <Center>
                    <Link href="https://www.instagram.com/north_fpv/"><Icon className="fab fa-instagram"/></Link>
                    <SubHeading>Instagram</SubHeading>
                    <Heading>
                        <NumberEasing
                            value={1404}
                            speed={300}
                            ease="cubicOut"/>
                    </Heading>
                </Center>
            </Column>

            <Column>
                <Center>
                    <Link href="https://youtube.com/c/NorthFPV"><Icon className="fab fa-youtube"/></Link>
                    <SubHeading>YouTube</SubHeading>
                    <Heading>
                        <NumberEasing
                            value={894}
                            speed={300}
                            ease="cubicOut"/>
                    </Heading>
                </Center>
            </Column>
            
        </Grid>
    </Container>
);