import styled from 'styled-components';
import { colors, fonts, media } from '../utils';

export const Container = styled.div`
    max-width: 1250px;
    margin: 0 auto;
    padding: 0 4vw;
`;

export const Title = styled(fonts.Heading)`
    border-bottom: 1px solid ${colors.lightGray};
    margin-bottom: 24px;
    padding-bottom: 24px;
    width: 100%;
`;

export const PostList = styled.ul`
    list-style: none;
    margin: 5rem auto;
    padding: 0;

    a {
        display: block;
        padding: 16px;
        text-decoration: none;

        ${media.medium`
            padding: 24px;
        `}
    }   
`;

export const PostListItem = styled.li`
    border: 1px solid ${colors.lightGray};
    border-radius: 2.5rem;
    display: block;
    transition: background-color 0.3s ease-in-out;

    &:hover {
        border: 1px solid ${colors.personality};
    }
`;

export const Date = styled(fonts.Eyebrow)`
    color: ${colors.darkGray};
    margin: 0;
`;

export const Tag = styled(fonts.Eyebrow)`
    color: ${colors.personality};
    display: inline;
    margin: 0;
`;

export const Heading = styled(fonts.Heading)`
    color: ${colors.black};
    margin: 8px 0 16px 0;
`;

export const SubHeading = styled(fonts.SubHeading)`
    color: ${colors.black};
    margin: 8px 0 16px 0;
`;

export const Preview = styled(fonts.Body)`
    color: ${colors.black};
    margin: 0;
`;

export const Thumbnail = styled.img`
    width: 100%
    height: 18rem;
    object-fit: cover;
    margin-right: 0;
    margin-bottom: 2rem;
    border-radius: 2.5rem;

    ${media.medium`
        width: 25%;
        margin-right: 2rem;
        margin-bottom: 0;
        height: 18rem;
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    ${media.medium`
        flex-direction: row;
    `}
`;

export const PaginationContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PageNext = styled.a`
    align-self: flex-end;
`;

export const PagePrev = styled.a`
    align-self: flex-start;
`;

export const Text = styled.div``;