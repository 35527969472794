import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
    Grid,
    Image
} from './styled'

import {
    AlignStart,
    Heading, 
    Paragraph,
    CallToAction,
} from '../../../pageStyles'

export const BestComponents = () => {

    const data = useStaticQuery(graphql`
        query {
            image: file(relativePath: {eq: "prodotti.jpg"}) {
                childImageSharp {
                fixed(width: 1920, height: 1080) {
                    srcWebp
                }
                }
            }
        }
    `);

    return (
        <Grid>
            <Image src={data.image.childImageSharp.fixed.srcWebp} />
            <AlignStart>
                <Heading>Costruisci un drone</Heading>
                <Paragraph>
                    Per costruire il tuo drone ti consigliamo di usare i prodotti che consigliamo. Grazie ad anni di esperienza, abbiamo creato una lista dei componenti migliori per costruire il tuo drone FPV.
                </Paragraph>
                <CallToAction href="/prodotti-consigliati">
                    Scopri i prodotti
                </CallToAction>
            </AlignStart>
        </Grid>
    );
};

