import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Container } from './styled';

import {
  PostList,
  PostListItem,
  Date,
  SubHeading,
  Preview
} from '../../../pageStyles/AllPostStyle';

import {
  Center,
  Heading,
  Paragraph
} from '../../../pageStyles'


export const BeginnerZone = () => {
    const data = useStaticQuery(graphql`
    query beginnerPosts {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { level: { in: ["Beginner"] } } }
          limit: 5
          skip: 0
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date(formatString: "DD MMMM YYYY", locale:"it")
                title
                excerpt
                author
              }
            }
          }
        }
      }
    `);
    const posts = data.allMarkdownRemark.edges;
    return (
      <Container>
        <Center>
          <Heading>Nuovo nel mondo FPV?</Heading>
          <Paragraph>Ci sono tante cose da imparare prima di inziare volare con un drone FPV. Ecco alcuni articoli che potrebbero aiutarti.</Paragraph>
        </Center>
          <PostList>
                {posts.map(( node, index ) => {
                    const { node : { frontmatter : { title, date, excerpt, author}, fields : { slug } } } = node;
                    return (
                        <PostListItem>
                            <Link to={`${slug}`} key={index}>
                                <Date>
                                    {date} &nbsp;&middot;&nbsp; @{author}
                                </Date>
                                <SubHeading>
                                    {title}
                                </SubHeading>
                                <Preview>
                                    {excerpt}
                                </Preview>
                        </Link>
                        </PostListItem>
                    )
                })}
            </PostList>
        </Container>
    );
}