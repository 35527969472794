import React from 'react'

import { Layout } from '../components/common'
import { BestComponents } from '../components/common/Components'
import { JoinTheClub } from '../components/common/JoinTheClub'
import { SocialProof } from '../components/common/SocialProof'
import { BeginnerZone } from '../components/common/BeginnerZone'
import { Divider } from '../pageStyles'
import { graphql } from 'gatsby'

const Index = ({data}) => {
  return (
    <>
      <Layout isHome={true} bgImage={data.bg.childImageSharp.fixed.srcWebp}>
        <div className="container">
          <BestComponents />
          <SocialProof />
          <Divider />
          <BeginnerZone />
          <Divider />
          <JoinTheClub />
        </div>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query {
    bg: file(relativePath: {eq: "site_banner.png"}) {
        childImageSharp {
          fixed(width: 1080, height: 720) {
              srcWebp
          }
        }
      }
  }
`;

export default Index;
