
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';

import {
    Heading,
    Paragraph,
    AlignStart,
    CallToAction
} from '../../../pageStyles';

import {
    Grid,
    Image,
} from './styled';

export const JoinTheClub = () => {
    const data = useStaticQuery(graphql`
        query {
            image: file(relativePath: {eq: "northfpv_2.jpg"}) {
                childImageSharp {
                fixed(width: 480, height: 300) {
                    srcWebp
                }
                }
            }
        }
    `);
    return (
        <Grid>
            <Image src={data.image.childImageSharp.fixed.srcWebp} />
            <AlignStart>
                <Heading>Vuoi far parte della nostro club?</Heading>
                <Paragraph>
                    Il Club è un gruppo esclusivo, nel quali i membri hanno dei benefit, e insieme decido la direzione di questo progetto. 
                </Paragraph>
                <Paragraph>
                    Scopri tutti i vantaggi che ottieni entrando nel club esclusivo di NorthFPV
                </Paragraph>
                <CallToAction href="/club">
                    Entra nel Club
                </CallToAction>
            </AlignStart>
        </Grid>
        );
}
