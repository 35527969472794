import styled from 'styled-components';
import { colors, fonts, media } from '../../../utils';

export const Grid = styled.section`
    margin: 3rem auto;
    display: grid;
    justify-content: space-between;
    grid-gap: 3rem;
    grid-template-columns: 1fr;

    ${media.medium`
        grid-template-columns: 1fr 1fr;
    `}
`;

export const Image = styled.img`
    border-radius: 2.5rem;
`;
